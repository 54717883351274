import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM:`}</p>
    <p>{`1-Snatch Grip Deadlift to just below knee`}</p>
    <p>{`1-:05 Pause just below knee`}</p>
    <p>{`1-Snatch Grip Deadlift `}<em parentName="p">{`(floor to full hip extension)`}</em></p>
    <p>{`1-Snatch Grip High Pull `}<em parentName="p">{`(above knee to high pull)`}</em></p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP:`}</p>
    <p>{`10-SDHP’s (75/55)(RX+ 95/65)`}</p>
    <p>{`10-Burpees Over Bar`}</p>
    <p>{`10-V Ups`}</p>
    <p>{`30-Double Unders`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      